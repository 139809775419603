import { IApiRequest, IApiResponse, fetchApi } from '../../core/auth/fetchApiHelper';
import { logger } from '../../core/diagnostics/logger';
import { processConfig } from '../../environment.constants';
import { IProductSearchNoResultsModel, IUserCartActivityMdoel } from './v2/anlytics.interfaces';

export const insertProductSearchNoResults = async (
  productSearchNoResultsModel: IProductSearchNoResultsModel
): Promise<IApiResponse<number>> => {
  try {
    const request: IApiRequest = {
      url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Analytics/insert-product-search-no-results-model`,
      method: 'POST',
      contentType: 'application/json',
      requireAccessToken: true,
      body: productSearchNoResultsModel
    };

    const response = await fetchApi<number>(request);
    return response;
  } catch (e) {
    logger.error('error inserting record after a shopping product search resulted in no records', e as Error);
    throw e;
  }
};

export const MergeUserCartActivity = async (
  userCartActivityModels: IUserCartActivityMdoel[]
): Promise<IApiResponse<IUserCartActivityMdoel>> => {
  try {
    const request: IApiRequest = {
      url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Analytics/merge-user-cart-activity`,
      method: 'POST',
      contentType: 'application/json',
      requireAccessToken: true,
      body: userCartActivityModels
    };

    const response = await fetchApi<IUserCartActivityMdoel>(request);
    return response;
  } catch (e) {
    logger.error('error merging user cart activity', e as Error);
    throw e;
  }
};
