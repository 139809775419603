import { RegistrationStatus } from './authentication.enumeration';

import {
  INIT,
  LOGIN_SUCCESS,
  REGISTER_NEW_USER_PENDING,
  REGISTER_NEW_USER_SUCCESS,
  CHANGE_REGISTRATION_STATUS
} from './authentication.actions.constants';
import {
  IInit,
  ILoginSuccess,
  IRegisterNewUserPending,
  IRegisterNewUserSuccess,
  IChangeRegistrationStatus
} from './authentication.actions.interfaces';
import { MecB2CAccountInfo } from './authentication.state.interfaces';

export const loginSuccessAction = (account: MecB2CAccountInfo): ILoginSuccess => {
  return { type: LOGIN_SUCCESS, payload: account };
};

export const registerNewUserPendingAction = (claims: any, idToken: string): IRegisterNewUserPending => {
  return {
    type: REGISTER_NEW_USER_PENDING,
    meta: { claims, idToken }
  };
};

export const registerNewUserSuccessAction = (): IRegisterNewUserSuccess => {
  return { type: REGISTER_NEW_USER_SUCCESS };
};

export const initAuthAction = (): IInit => {
  return { type: INIT };
};

export const changeRegistrationStatusAction = (registrationStatus: RegistrationStatus): IChangeRegistrationStatus => {
  return { type: CHANGE_REGISTRATION_STATUS, meta: { registrationStatus } };
};
