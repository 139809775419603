import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { logger } from '../diagnostics/logger';
import { processConfig } from '../../environment.constants';

import { setupMsalEvents } from './authentication.msal.events';

export const msalConfig: Configuration = {
  auth: {
    authority: processConfig.auth.msal.authorityLogin,
    clientId: processConfig.auth.msal.clientId,
    knownAuthorities: [processConfig.auth.msal.knownAuthority],
    postLogoutRedirectUri: processConfig.auth.msal.postRedirectUrl,
    redirectUri: processConfig.auth.msal.redirectUrl
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            logger.error('error in msal auth', new Error(message));
            return;
          case LogLevel.Info:
            if (processConfig.auth.logNonErrorAuth) {
              logger.info(message);
            }
            return;
          case LogLevel.Verbose:
            if (processConfig.auth.logNonErrorAuth) {
              logger.trace(message);
            }
            return;
          case LogLevel.Warning:
            if (processConfig.auth.logNonErrorAuth) {
              logger.warn(message);
            }
            return;
        }
      },
      piiLoggingEnabled: false
    }
  }
};

export const publicClientApplication = await PublicClientApplication.createPublicClientApplication(msalConfig);

setupMsalEvents();
