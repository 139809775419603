import { createSelector } from 'reselect';

import { IState } from '../../state/store.state';

import { ICustomerState } from './customer.state.interface';

const customerSelector = (state: IState) => state.customer;

export const customerInfoSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => customer.customerInfo
);

export const isSuperUserSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => customer.customerImpersonationInfoList.length > 0
);

export const superUserCustomerInfoSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => customer.customerImpersonationInfoList
);

export const showImpersonationSelectDialogSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => customer.showImpersonationSelectDialog
);

export const isImpersonationPendingSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => customer.impersonationPending
);

export const impersonatedUserIdSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => customer.impersonation?.userGuid
);

export const impersonatedUserSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => customer.impersonation
);

export const isSuperUserNotImpersonatedSelector = createSelector(
  customerSelector,
  (customer: ICustomerState) => !customer.impersonation && customer.customerImpersonationInfoList.length > 0
);
