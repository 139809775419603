import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from '@mui/material';

import { IState } from '../../../state/store.state';
import { setCustomerInfoForSuperUserAction } from '../customer.actions';
import { superUserCustomerInfoSelector } from '../customer.selectors';

const ImpersonationList = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const customerList = useSelector((state: IState) => superUserCustomerInfoSelector(state)).filter((f) =>
    f.displayName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
    dispatch(setCustomerInfoForSuperUserAction(customerList[index]));
  };

  const handleImpersonate = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event.currentTarget);
  };

  return (
    <Box height="250px">
      <TextField
        onChange={(e) => setSearch(e.target.value)}
        id="outlined-basic"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      <List dense={true}>
        {customerList.map((cl, i) => (
          <ListItem key={i} button selected={selectedIndex === i} onClick={(event) => handleListItemClick(event, i)}>
            <ListItemText primary={cl.displayName} secondary={cl.company + ' (tier ' + cl.pricingTier + ')'} />
            <ListItemSecondaryAction>
              <IconButton onClick={handleImpersonate} edge="end" aria-label="impersonate">
                <AccountCircleIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ImpersonationList;
