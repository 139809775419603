import * as filterActionInterface from './filter.actions.interfaces';
import { FILTER_DELETE_PENDING, FILTER_RESET, FILTER_SELECTION_PENDING } from './filter.actions.constants';
import { IFilterState } from './filter.state.interfaces';
import { isObjectArray } from './filter.util';

export const initialState: IFilterState = {
  isPending: false,
  selectedFilters: [],
  alloy: null,
  temper: null,
  gauge: null,
  width: null,
  length: null,
  finish: null,
  warehouseNames: []
};

const handleFilterSelectionPending = (
  state: IFilterState,
  action: filterActionInterface.IFilterSelectionPending
): IFilterState => {
  return {
    ...state,
    [action.meta.filterChange.name]: action.meta.filterChange.value
  };
};

const handleFilterReset = (state: IFilterState): IFilterState => {
  return {
    ...state,
    alloy: null,
    temper: null,
    gauge: null,
    width: null,
    length: null,
    finish: null,
    warehouseNames: []
  };
};

const handleFilterDeletePending = (
  state: IFilterState,
  action: filterActionInterface.IFilterDeletePending
): IFilterState => {
  const isMultiSelect = isObjectArray(state[action.meta.filterName]);
  return { ...state, [action.meta.filterName]: isMultiSelect ? [] : null };
};

const filterReducer = (
  state: IFilterState = initialState,
  action:
    | filterActionInterface.IFilterSelectionPending
    | filterActionInterface.IFilterReset
    | filterActionInterface.IFilterDeletePending
): IFilterState => {
  switch (action.type) {
    case FILTER_SELECTION_PENDING:
      return handleFilterSelectionPending(state, action);
    case FILTER_RESET:
      return handleFilterReset(state);
    case FILTER_DELETE_PENDING:
      return handleFilterDeletePending(state, action);
    default:
      return state;
  }
};

export default filterReducer;
