import { RegistrationStatus } from './authentication.enumeration';
import { LOGIN_SUCCESS, CHANGE_REGISTRATION_STATUS } from './authentication.actions.constants';
import { ILoginSuccess, IChangeRegistrationStatus } from './authentication.actions.interfaces';
import { IAuthenticationState } from './authentication.state.interfaces';

export const initialState: IAuthenticationState = {
  account: undefined,
  registrationStatus: RegistrationStatus.InitialState
};

const loginSuccess = (state: IAuthenticationState, action: ILoginSuccess): IAuthenticationState => {
  return { ...state, account: action.payload };
};

const authReducer = (
  state: IAuthenticationState = initialState,
  action: ILoginSuccess | IChangeRegistrationStatus
): IAuthenticationState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case CHANGE_REGISTRATION_STATUS:
      return { ...state, registrationStatus: action.meta.registrationStatus };
    default:
      return state;
  }
};

export default authReducer;
