import { createSelector } from 'reselect';

import { IAuthenticationState } from '../../core/auth/authentication.state.interfaces';
import { IState } from '../../state/store.state';

import { IFilterCriteria, IFilterState } from './filter.state.interfaces';

const filters = (appState: IState) => appState.filters;
const auth = (appState: IState) => appState.authentication;

const getFilterCriteria = createSelector([filters, auth], (filter: IFilterState, auth: IAuthenticationState) => {
  const userId = auth.account ? auth.account.homeAccountId : '';

  const criteria: IFilterCriteria = {
    userId,
    alloy: filter.alloy,
    temper: filter.temper,
    gauge: filter.gauge,
    width: filter.width,
    length: filter.length,
    finish: filter.finish,
    warehouseNames: filter.warehouseNames
  };

  return criteria;
});

export const isPendingSelector = (state: IState) => state.filters.isPending;
export const getGaugeValueSelector = (state: IState) => state.filters.gauge;
export const getAlloyValueSelector = (state: IState) => state.filters.alloy;
export const getTemperValueSelector = (state: IState) => state.filters.temper;
export const getFinishValueSelector = (state: IState) => state.filters.finish;
export const getWidthValueSelector = (state: IState) => state.filters.width;
export const getLengthValueSelector = (state: IState) => state.filters.length;
export const getWarehouseValueSelector = (state: IState) => state.filters.warehouseNames;
export const getFilterCriteriaSelector = (state: IState): IFilterCriteria => getFilterCriteria(state);
