import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';

import { processConfig } from '../../environment.constants';
import { logger } from '../diagnostics/logger';

import { publicClientApplication } from './authentication.config';
import { MecB2CAccountInfo } from './authentication.state.interfaces';

export const getAccount = (): MecB2CAccountInfo | undefined => {
  const accounts = publicClientApplication.getAllAccounts();
  if (accounts.length === 1) {
    return accounts[0] as MecB2CAccountInfo;
  }

  if (accounts.length > 1) {
    publicClientApplication.loginPopup({
      scopes: [],
      prompt: 'select_account'
    });
  }

  return undefined;
};

export const logout = () => {
  publicClientApplication.logoutRedirect();
};

export const register = () => {
  publicClientApplication.loginRedirect({
    authority: processConfig.auth.msal.authorityRegister,
    scopes: []
  });
};

export const getToken = async () => {
  const currentAccount = getAccount();
  if (currentAccount !== null) {
    const silentRequest = {
      scopes: [],
      account: currentAccount,
      forceRefresh: false
    };

    const request = {
      scopes: [processConfig.auth.msal.loginScope || ''],
      loginHint: currentAccount && currentAccount.username
    };

    return await publicClientApplication
      .acquireTokenSilent(silentRequest)
      .then((res: AuthenticationResult) => {
        return res.idToken;
      })
      .catch((error) => {
        //// TODO: Still not working
        //// Do something with the homeAccountId or userName
        //// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/accounts.md
        logger.error('token response error', error);
        if (error instanceof InteractionRequiredAuthError) {
          return publicClientApplication.acquireTokenRedirect(request);
        }
      });
  }
};

export const forgotPasswordRequest = {
  authority: processConfig.auth.msal.authorityPassword,
  scopes: []
};

export const editProfileRequest = {
  authority: processConfig.auth.msal.authorityProfile,
  scopes: []
};
