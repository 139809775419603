import { EventType, InteractionType } from '@azure/msal-browser';

import { toggleAlertDialogAction } from '../../app/Notification/notification.actions';
import { processConfig } from '../../environment.constants';
import { store } from '../../state/store';
import { logger } from '../diagnostics/logger';

import { initAuthAction, registerNewUserPendingAction } from './authentication.actions';
import { publicClientApplication } from './authentication.config';
import { forgotPasswordRequest, logout } from './authentication.msal.api';

const fileName = 'auth.msal.events.ts';

export const setupMsalEvents = () => {
  publicClientApplication.addEventCallback((message: any) => {
    switch (message.eventType) {
      case EventType.ACQUIRE_TOKEN_SUCCESS: {
        store.dispatch(initAuthAction());
        break;
      }
      case EventType.ACQUIRE_TOKEN_FAILURE: {
        logger.warn(`ACQUIRE_TOKEN_FAILURE: ${JSON.stringify(message)}`, fileName);
        break;
      }
      case EventType.LOGIN_SUCCESS: {
        if (message.payload.idTokenClaims.tfp === processConfig.auth.msal.userFlowRegister) {
          store.dispatch(registerNewUserPendingAction(message.payload.idTokenClaims, message.payload.idToken));
          break;
        }
        if (message.payload.idTokenClaims.tfp === processConfig.auth.msal.userFlowPassword) {
          store.dispatch(
            toggleAlertDialogAction(true, {
              message: 'Password has been reset successfully.',
              onClose: logout
            })
          );
        } else {
          store.dispatch(initAuthAction());
        }
        break;
      }
      case EventType.LOGIN_FAILURE:
        if (processConfig.auth.logNonErrorAuth) {
          logger.warn(`EventType.LOGIN_FAILURE:' ${JSON.stringify(message)}`, fileName);
        }

        if (
          message.interactionType === InteractionType.Redirect &&
          message.error.errorMessage &&
          message.error.errorMessage.indexOf('AADB2C90118') > -1
        ) {
          publicClientApplication.loginRedirect(forgotPasswordRequest);
        } else {
          console.log('redirect');
        }
        break;
      case EventType.HANDLE_REDIRECT_START:
        if (processConfig.auth.logNonErrorAuth) {
          logger.trace(JSON.stringify(message), fileName);
        }
        break;
      case EventType.HANDLE_REDIRECT_END:
        if (processConfig.auth.logNonErrorAuth) {
          logger.trace(JSON.stringify(message), fileName);
        }
        break;
      default:
        if (processConfig.auth.logNonErrorAuth) {
          logger.info(`EventType not handled by sekota:' ${JSON.stringify(message)}`, fileName);
        }
    }
  });
};
