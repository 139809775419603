import * as bulletinActionInterfaces from './bulletin.actions.interfaces';
import {
  GET_BULLETIN_ITEMS_SUCCESS,
  TOGGLE_DISCOUNTED_ITEMS,
  TOGGLE_NEW_PRODUCTS,
  TOGGLE_NOTICES
} from './bulletin.actions.constants';
import { IBulletinState } from './bulletin.state.interfaces';

export const initialState: IBulletinState = {
  isPending: true,
  showDiscountedItems: false,
  showNewProducts: false,
  showNotices: false,
  discountedItems: null,
  newProducts: null,
  notices: null,
  items: []
};

const handleToggleDiscountedItems = (state: IBulletinState): IBulletinState => {
  return {
    ...state,
    showDiscountedItems: !state.showDiscountedItems
  };
};

const handleToggleNewProducts = (state: IBulletinState): IBulletinState => {
  return { ...state, showNewProducts: !state.showNewProducts };
};

const handleToggleNotices = (state: IBulletinState): IBulletinState => {
  return { ...state, showNotices: !state.showNotices };
};

const handleGetItemSuccess = (state: IBulletinState, action: bulletinActionInterfaces.IGetBulletinItemsSuccess) => {
  return { ...state, items: action.payload.items, isPending: false };
};

const bulletinReducer = (
  state: IBulletinState = initialState,
  action:
    | bulletinActionInterfaces.IToggleDiscountedItems
    | bulletinActionInterfaces.IToggleNewProducts
    | bulletinActionInterfaces.IToggleNotices
    | bulletinActionInterfaces.IGetBulletinItemsSuccess
): IBulletinState => {
  switch (action.type) {
    case TOGGLE_DISCOUNTED_ITEMS:
      return handleToggleDiscountedItems(state);
    case TOGGLE_NEW_PRODUCTS:
      return handleToggleNewProducts(state);
    case TOGGLE_NOTICES:
      return handleToggleNotices(state);
    case GET_BULLETIN_ITEMS_SUCCESS:
      return handleGetItemSuccess(state, action);
  }

  return state;
};

export default bulletinReducer;
