import reduxSaga from 'redux-saga';
import { combineReducers, configureStore, ReducersMapObject } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';

import { createBrowserHistory } from 'history';
import analyticsSaga from '../app/Analytics/analytics.saga';
import authReducer from '../core/auth/authentication.reducer';
import authSaga from '../core/auth/authentication.saga';
import authorizationReducer from '../app/Authorization/authorization.reducer';
import authorizationSaga from '../app/Authorization/authorization.saga';
import bulletinReducer from '../app/Main/bulletin.reducer';
import bulletinSaga from '../app/Main/bulletin.saga';
import customerPoReducer from '../app/CustomerOrderInfo/customerPo.reducer';
import customerPoSaga from '../app/CustomerOrderInfo/customerPo.saga';
import customerReducer from '../app/Customer/customer.reducer';
import customerSaga from '../app/Customer/customer.saga';
import fileDownloadSaga from '../app/FileDownload/fileDownload.saga';
import filterReducer from '../app/Filter/filter.reducer';
import filterSaga from '../app/Filter/filter.saga';
import layoutReducer from '../layout/layout.reducer';
import layoutSaga from '../layout/layout.saga';
import notificationReducer from '../app/Notification/notification.reducer';
import orderHistoryReducer from '../app/OrderHistory/order.history.reducer';
import orderHistorySaga from '../app/OrderHistory/order.history.saga';
import productReducer from '../app/Product/product.reducer';
import productSaga from '../app/Product/product.saga';
import settingsReducer from '../core/settings/settings.reducer';
import shoppingReducer from '../app/Shopping/shopping.reducer';
import shoppingSaga from '../app/Shopping/shopping.saga';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
});

const getReducers = () => {
  const coreReducers: ReducersMapObject<{}, any> = {
    router: routerReducer,
    authentication: authReducer,
    authorization: authorizationReducer,
    bulletin: bulletinReducer,
    customer: customerReducer,
    customerPo: customerPoReducer,
    filters: filterReducer,
    layout: layoutReducer,
    notification: notificationReducer,
    orderHistory: orderHistoryReducer,
    products: productReducer,
    settings: settingsReducer,
    shopping: shoppingReducer
  };

  return combineReducers(coreReducers);
};
const sagaMiddleware = reduxSaga();

export const store = configureStore({
  reducer: getReducers(),
  middleware: [sagaMiddleware, routerMiddleware]
});

export const reduxHistory = createReduxHistory(store);

export const setSagas = () => {
  sagaMiddleware.run(authSaga);
  sagaMiddleware.run(authorizationSaga);
  sagaMiddleware.run(layoutSaga);
  sagaMiddleware.run(customerSaga);
  sagaMiddleware.run(customerPoSaga);
  sagaMiddleware.run(filterSaga);
  sagaMiddleware.run(productSaga);
  sagaMiddleware.run(shoppingSaga);
  sagaMiddleware.run(bulletinSaga);
  sagaMiddleware.run(orderHistorySaga);
  sagaMiddleware.run(fileDownloadSaga);
  sagaMiddleware.run(analyticsSaga);
};
