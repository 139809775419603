import { logger } from '../../core/diagnostics/logger';

import AlertType from './notification.enum';
import { CLOSE_NOTIFICATION, OPEN_NOTIFICATION, TOGGLE_ALERT_DIALOG } from './notification.actions.constants';
import { ICloseNotification, IOpenNotificatiion, IToggleAlertDialog } from './notification.actions.interface';
import { IAlertDialogOptions } from './notification.state.interface';

export const closeNotificationAction = (): ICloseNotification => {
  return { type: CLOSE_NOTIFICATION };
};
export const notifyWarnAction = (message: string, displayTime = 6000, log = true): IOpenNotificatiion => {
  if (log) {
    logger.warn(message);
  }
  return {
    type: OPEN_NOTIFICATION,
    meta: { message, displayTime, alertType: AlertType.Warning }
  };
};

export const notifyErrorAction = (
  error?: Error,
  message = 'Oops. Something went wrong.  Please contact your Sales Representative.',
  displayTime = 6000,
  log = true
): IOpenNotificatiion => {
  if (log && !error) {
    logger.error('error', new Error(message));
  } else if (log && error) {
    logger.error(message, error);
  }
  return {
    type: OPEN_NOTIFICATION,
    meta: { message, displayTime, alertType: AlertType.Error }
  };
};

export const notifySuccessAction = (message = 'Success', displayTime = 3000, log = false): IOpenNotificatiion => {
  if (log) {
    logger.info(message);
  }
  return {
    type: OPEN_NOTIFICATION,
    meta: { message, displayTime, alertType: AlertType.Success }
  };
};

export const notifyInfoAction = (message: string, displayTime = 3000, log = false): IOpenNotificatiion => {
  if (log) {
    logger.info(message);
  }
  return {
    type: OPEN_NOTIFICATION,
    meta: { message, displayTime, alertType: AlertType.Info }
  };
};

export const toggleAlertDialogAction = (
  showAlertDialog: boolean,
  alertDialogOptions: IAlertDialogOptions
): IToggleAlertDialog => {
  return { type: TOGGLE_ALERT_DIALOG, meta: { showAlertDialog, alertDialogOptions } };
};
