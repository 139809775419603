import { createSelector } from 'reselect';
import { IFilterCriteria } from '../Filter/filter.state.interfaces';
import { IState } from '../../state/store.state';
import { ICustomerState } from '../Customer/customer.state.interface';
import { IAuthenticationState } from '../../core/auth/authentication.state.interfaces';
import { isSuperUserSelector } from '../Customer/customer.selectors';
import {
  CartActivityTypeEnum,
  ICartItem,
  IProductSearchNoResultsModel,
  IUserCartActivityMdoel
} from './v2/anlytics.interfaces';

const filterCriteriaSelector = (appState: IState) => appState.filters;
const customerSelector = (state: IState) => state.customer;
const authenticationSelector = (state: IState) => state.authentication;

export const productSearchNoResultsModelSelector = createSelector(
  [filterCriteriaSelector, customerSelector, authenticationSelector, isSuperUserSelector],
  (
    filterCriteria: IFilterCriteria,
    customer: ICustomerState,
    authentication: IAuthenticationState,
    isSuperUser: boolean
  ) => {
    const productSearchNoResultsModel: IProductSearchNoResultsModel = {
      userAzureADId: isSuperUser ? customer.impersonation?.userGuid : authentication.account?.localAccountId,
      emailAddress: isSuperUser ? customer.impersonation?.emailAddress : authentication.account?.username,
      superUserAzureId: isSuperUser ? authentication.account?.localAccountId : null,
      superUserEmailAddress: isSuperUser ? authentication.account?.username : null,
      isInternalCompanyEmployee: isSuperUser,
      alloy: filterCriteria.alloy,
      finish: filterCriteria.finish,
      gauge: filterCriteria.gauge,
      length: filterCriteria.length,
      companyName: customer.customerInfo.company,
      temper: filterCriteria.temper,
      width: filterCriteria.width,
      warehouseName: filterCriteria.warehouseNames?.toString(),
      castNumber: null
    };

    return productSearchNoResultsModel;
  }
);

export const createCartActivitySelector = (cartActivityTypeId: CartActivityTypeEnum, cartItems: ICartItem[]) =>
  createSelector(
    [authenticationSelector, customerSelector, isSuperUserSelector],
    (
      authentication: IAuthenticationState,
      customer: ICustomerState,
      isSuperUser: boolean
    ): IUserCartActivityMdoel[] => {
      return cartItems.map((cartItem) => {
        const userCartActivityModel: IUserCartActivityMdoel = {
          userAzureADId: isSuperUser ? customer.impersonation?.userGuid : authentication.account?.localAccountId,
          emailAddress: isSuperUser ? customer.impersonation?.emailAddress : authentication.account?.username,
          superUserAzureId: isSuperUser ? authentication.account?.localAccountId : null,
          superUserEmailAddress: isSuperUser ? authentication.account?.username : null,
          isInternalCompanyEmployee: isSuperUser,
          cartActivityDate: new Date(),
          cartActivityTypeId: cartActivityTypeId,
          companyName: isSuperUser ? customer.impersonation?.company : customer.customerInfo.company,
          productIdentifier: cartItem.item_id
        };

        return userCartActivityModel;
      });
    }
  );
