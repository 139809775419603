import { StrictEffect, call, delay, put, select } from 'redux-saga/effects';

import { authWait, getUserId } from '../../core/auth/authentication.saga';
import { IApiResponse } from '../../core/auth/fetchApiHelper';
import { logger } from '../../core/diagnostics/logger';
import { impersonatedUserIdSelector, isImpersonationPendingSelector } from '../Customer/customer.selectors';
import { toggleAlertDialogAction } from '../Notification/notification.actions';
import { IAlertDialogOptions } from '../Notification/notification.state.interface';
import { processConfig } from '../../environment.constants';

import {
  shoppingCartExpirationMonitorStartAction,
  shoppingCartMonitorStartAction,
  shoppingToggleExpiredCartAction,
  updateCartItemsAtServerAction
} from './shopping.actions';
import { ICartResponseModel, getIsCartExpired, updateItems } from './shopping.api';
import { ICartModel } from './shopping.state.interface';
import {
  cartItemsAtServerSelector,
  cartProductIdentifiersSelector,
  isCartExpiredSelector,
  isCheckoutPendingSelector
} from './shopping.selectors';

const fileName = 'shopping.saga.monitor.ts';

export function* monitorCart(): Generator<StrictEffect, any, any> {
  try {
    const isCheckoutPending = yield select(isCheckoutPendingSelector);

    if (isCheckoutPending) {
      logger.info('exiting shopping cart monitor - may not be ran while user has a checkout pending...');
      return;
    }

    const isImpersonationPending = yield select(isImpersonationPendingSelector);

    if (isImpersonationPending) {
      logger.info('exiting shopping cart monitor - may not be ran while impersonation is pending...');
      return;
    }

    const cartItemsAtServer: string[] | undefined = yield select(cartItemsAtServerSelector);

    const cartItemsAtClient: string[] = yield select(cartProductIdentifiersSelector);

    const clientServerAgreement =
      cartItemsAtServer && cartItemsAtServer.sort().toString() == cartItemsAtClient.sort().toString();

    if (!clientServerAgreement) {
      yield call(authWait);
      const userId = yield call(getUserId);

      const impersonatedUserId: string = yield select(impersonatedUserIdSelector);

      const cartModel: ICartModel = {
        userId: impersonatedUserId || userId,
        productIdentifiers: cartItemsAtClient
      };

      const response: IApiResponse<ICartResponseModel> = yield call(updateItems, cartModel);

      logger.info('The updated cart is: \n\n' + JSON.stringify(response.payload?.updatedCart), fileName);

      yield put(updateCartItemsAtServerAction(response.payload?.updatedCart.productIdentifiers));
      if (response.payload && response.payload.invalidProductIdentifiers.length > 0) {
        const dialogOptions: IAlertDialogOptions = {
          onClose: null,
          title: 'Oops - there was a problem',
          message:
            'Some items could not be added to the shopping cart.  This usually occurs because another user has reserved your inventory.'
        };

        yield put(toggleAlertDialogAction(true, dialogOptions));
      }
    }
  } catch (e) {
    logger.error('monitorCart', e as Error, fileName);
  }

  logger.info(
    `Shopping cart monitor ran and will run again in ${processConfig.intervals.shoppingCartMonitorInterval}.`,
    fileName
  );

  yield delay(processConfig.intervals.shoppingCartMonitorInterval);

  yield put(shoppingCartMonitorStartAction());
}

export function* monitorCartExpiration(): Generator<StrictEffect, any, any> {
  try {
    yield call(authWait);
    const isCartExpired: boolean = yield select(isCartExpiredSelector);

    if (!isCartExpired) {
      const userId: string = yield call(getUserId);
      const impersonatedUserId: string = yield select(impersonatedUserIdSelector);

      const response = yield call(getIsCartExpired, impersonatedUserId || userId);

      if (response.payload === true) {
        logger.warn('Shopping cart items expired');
        yield put(shoppingToggleExpiredCartAction(true));
      }
    }
  } catch (e) {
    logger.error('monitorCartExpiration', e as Error, fileName);
  }

  logger.info(
    `Shopping cart expiration monitor ran and will run again in ${processConfig.intervals.shoppingCartExpirationInterval}.`,
    fileName
  );

  yield delay(processConfig.intervals.shoppingCartExpirationInterval);

  yield put(shoppingCartExpirationMonitorStartAction());
}
