import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';

import { logger } from '../../core/diagnostics/logger';

import { IFileCapturePending } from './customerPo.action.interfaces';
import { fileCaptureErrorAction, fileCaptureSuccessAction } from './customerPo.actions';
import { FILE_CAPTURE_PENDING } from './customerPo.actions.constants';

const fileName = 'customerPo.saga.ts';

export const readUploadedFileAsBinaryString = (inputFile: File) => {
  const reader = new FileReader();

  return new Promise<string | null | ArrayBuffer>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsBinaryString(inputFile);
  });
};

function* processFileUpload(action: IFileCapturePending) {
  try {
    if (action.meta.event.target && action.meta.event.target.files) {
      const file = action.meta.event.target.files[0];

      const fileBinaryString: string = yield readUploadedFileAsBinaryString(file);
      const base64 = btoa(fileBinaryString);

      yield action.meta.processFileFunction(action.meta.orderId, base64, file.name);

      yield put(fileCaptureSuccessAction());
    } else {
      yield put(fileCaptureErrorAction());
      logger.error('processFileUpload', new Error('File capture was null'), fileName);
    }
  } catch (e) {
    logger.error('processFileUpload', e as Error, fileName);

    yield put(fileCaptureErrorAction());
  }
}

function* customerPoSaga(): SagaIterator {
  yield takeLatest(FILE_CAPTURE_PENDING, processFileUpload);
}

export default customerPoSaga;
