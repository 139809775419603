import { SagaIterator } from 'redux-saga';
import { StrictEffect, call, put, select, takeLatest } from 'redux-saga/effects';

import JSZip from 'jszip';
import saveAs from 'file-saver';
import { getToken } from '../../core/auth/authentication.msal.api';
import { authWait, getUserId } from '../../core/auth/authentication.saga';
import { logger } from '../../core/diagnostics/logger';
import { INavigate } from '../../layout/layout.actions.interfaces';
import { NAVIGATE } from '../../layout/layout.constants';
import { impersonatedUserIdSelector } from '../Customer/customer.selectors';
import { resetCustomerPoFileAction, resetCustomerPoNumberAction } from '../CustomerOrderInfo/customerPo.actions';
import { ICustomerPoFile, ICustomerPoNumber } from '../CustomerOrderInfo/customerPo.state.interface';
import { customerPoFileSelector, customerPoNumberSelector } from '../CustomerOrderInfo/customerPo.selectors';
import { notifyErrorAction, toggleAlertDialogAction } from '../Notification/notification.actions';

import { IAlertDialogOptions } from '../Notification/notification.state.interface';
import { downloadOrderDocument } from '../FileDownload/fileDownlaod.api';
import { getCertificate } from '../Product/product.api';
import {
  downloadCheckedDocumentsErrorAction,
  getOrderHistoryErrorAction,
  getOrderHistoryPendingAction,
  getOrderHistorySuccessAction,
  getPackingListFileActionError,
  getPackingListFileActionSuccess,
  updateCustomerPoFileErrorAction,
  updateCustomerPoFileSuccessAction,
  updateCustomerPoNumberErrorAction,
  updateCustomerPoNumberSuccessAction
} from './order.history.actions';
import {
  GET_ORDER_HISTORY_PENDING,
  SET_BEGIN_FILTER_DATE,
  SET_END_FILTER_DATE,
  UPDATE_CUSTOMER_PO_FILE_PENDING,
  UPDATE_CUSTOMER_PO_NUMBER_PENDING,
  SEARCH,
  RESET_FILTERS,
  GET_PACKING_LIST_FILE_PENDING,
  DOWNLOAD_CHECKED_DOCUMENT_PENDING
} from './order.history.actions.constants';
import { getOrderHistory, updateCustomerPoFile, updateCustomerPoNumber } from './order.history.api';
import { beginDateFilterSelector, endDateFilterSelector, searchTextSelector } from './order.history.selectors';
import { IDownloadCheckedDocumentsPending, IGetPackingListFilePending } from './order.history.actions.interfaces';
import { IOrderHistoryDocumentModel } from './order.history.state.interfaces';
import { OrderDocumentType } from './order.history.enums';

const fileName = 'order.history.saga.ts';

function* getOrderHistoryPending(): Generator<StrictEffect, any, any> {
  yield call(authWait);
  const userId = yield call(getUserId);

  const impersonatedUserId: string = yield select(impersonatedUserIdSelector);
  const beginDate = yield select(beginDateFilterSelector);
  const endDate = yield select(endDateFilterSelector);
  const searchText = yield select(searchTextSelector);

  if (impersonatedUserId) {
    logger.info('Getting order history  with impersonated user id');
  }

  const response = yield call(getOrderHistory, impersonatedUserId || userId, beginDate, endDate, searchText);

  try {
    yield put(getOrderHistorySuccessAction(response.payload));
  } catch (e) {
    logger.error('getOrderHistoryPending', e as Error, fileName);
    yield put(getOrderHistoryErrorAction());
    yield put(notifyErrorAction(e as Error, 'We are having trouble showing your order history.  Please try again.'));
  }
}

function* navigate(action: INavigate) {
  if (action.meta.url === '/order-history') {
    yield put(getOrderHistoryPendingAction());
  }
}

function* updateCustomerPoFilePending() {
  try {
    yield call(authWait);
    const customerPoFile: ICustomerPoFile = yield select(customerPoFileSelector);
    yield call(updateCustomerPoFile, customerPoFile);
    yield put(updateCustomerPoFileSuccessAction());
    yield put(resetCustomerPoFileAction(0));
    yield put(getOrderHistoryPendingAction());
  } catch (e) {
    logger.error('updateCustomerPoFilePending', e as Error, fileName);
    yield put(updateCustomerPoFileErrorAction());
    yield put(
      notifyErrorAction(
        e as Error,
        'We are unable to upload your customer PO at this time.  Please contact your Sales Representative.'
      )
    );
  }
}

function* updateCustomerPoNumberPending() {
  try {
    yield call(authWait);
    const customerPoNumber: ICustomerPoNumber = yield select(customerPoNumberSelector);
    yield call(updateCustomerPoNumber, customerPoNumber);
    yield put(updateCustomerPoNumberSuccessAction());
    yield put(resetCustomerPoNumberAction(0));
    yield put(getOrderHistoryPendingAction());
  } catch (e) {
    logger.error('updateCustomerPoNumberPending', e as Error, fileName);
    yield put(updateCustomerPoNumberErrorAction());
    yield put(
      notifyErrorAction(
        e as Error,
        'We are unable to update your customer PO number at this time.  Please try again later'
      )
    );
  }
}

function* search() {
  yield put(getOrderHistoryPendingAction());
}

function* filter() {
  yield call(getOrderHistoryPending);
}

function* getPackingListFilePending(action: IGetPackingListFilePending): Generator<StrictEffect, any, any> {
  try {
    yield call(authWait);
    const tokenResponse = yield call(getToken);
    const response = yield call(downloadOrderDocument, action.meta.url, tokenResponse);
    saveAs(response, action.meta.fileName);
    const objectURL = URL.createObjectURL(response);
    window.open(objectURL);
    yield put(getPackingListFileActionSuccess(response));
  } catch (e) {
    logger.error('getPackingListFilePending', e as Error, fileName);

    const dialogOptions: IAlertDialogOptions = {
      title: 'Error Downloading Packing List',
      message: 'Please Contact Your MX Sales Representative If Issue Persists.'
    };

    yield put(toggleAlertDialogAction(true, dialogOptions));
    yield put(getPackingListFileActionError(e as Error));
  }
}

function* downloadCheckedDocumentsPending(action: IDownloadCheckedDocumentsPending): Generator<any, any, any> {
  try {
    yield call(downloadAndSaveAsZip, action.meta.documents);
  } catch (error) {
    const dialogOptions: IAlertDialogOptions = {
      title: 'Error Downloading Order Documents as zip file',
      message: 'Please Contact Your MX Sales Representative If Issue Persists.'
    };

    yield put(toggleAlertDialogAction(true, dialogOptions));
    yield put(downloadCheckedDocumentsErrorAction(error as Error));
  }
}

const downloadAndSaveAsZip = async (documents: IOrderHistoryDocumentModel[]): Promise<any> => {
  const zip = new JSZip();

  const token = await getToken();
  for (const document of documents) {
    if (document.orderDocumentType === OrderDocumentType.Certification) {
      const cert = (await getCertificate(document.location, token as string)).payload;
      zip.file(document.name, cert);
    } else {
      const response = await downloadOrderDocument(document.location, token || '');
      zip.file(document.name, response);
    }
  }

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, `MXPORTAL_Order_${documents[0].orderId}_Documents.zip`);
};

function* OrderHistorySaga(): SagaIterator {
  yield takeLatest(NAVIGATE, navigate);
  yield takeLatest(GET_ORDER_HISTORY_PENDING, getOrderHistoryPending);
  yield takeLatest(UPDATE_CUSTOMER_PO_FILE_PENDING, updateCustomerPoFilePending);
  yield takeLatest(UPDATE_CUSTOMER_PO_NUMBER_PENDING, updateCustomerPoNumberPending);
  yield takeLatest(SET_BEGIN_FILTER_DATE, filter);
  yield takeLatest(SET_END_FILTER_DATE, filter);
  yield takeLatest(RESET_FILTERS, filter);
  yield takeLatest(SEARCH, search);
  yield takeLatest(GET_PACKING_LIST_FILE_PENDING, getPackingListFilePending);
  yield takeLatest(DOWNLOAD_CHECKED_DOCUMENT_PENDING, downloadCheckedDocumentsPending);
}

export default OrderHistorySaga;
