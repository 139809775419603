import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

import { IState } from '../../../state/store.state';
import { toggleShoppingCartAction, viewShoppingCartPendingAction } from '../shopping.actions';
import { cartCountSelector } from '../shopping.selectors';
import { isSuperUserNotImpersonatedSelector } from '../../Customer/customer.selectors';
import { notifyWarnAction } from '../../Notification/notification.actions';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px'
    }
  })
)(Badge);

const ShoppingCartBadge = () => {
  const dispatch = useDispatch();
  const cartCount = useSelector((state: IState) => cartCountSelector(state));
  const isSuperUserNotImpersonated = useSelector((state: IState) => isSuperUserNotImpersonatedSelector(state));

  const handleCartClick = () => {
    if (isSuperUserNotImpersonated) {
      dispatch(notifyWarnAction('Super Users must impersonate a user to view shopping cart'));
    } else {
      dispatch(toggleShoppingCartAction());
      dispatch(viewShoppingCartPendingAction());
    }
  };

  return (
    <div onClick={handleCartClick}>
      <StyledBadge badgeContent={cartCount} color="secondary">
        <ShoppingCartIcon />
      </StyledBadge>
    </div>
  );
};

export default ShoppingCartBadge;
