import React, { ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import paths from '../../layout/components/router/paths';
import { navigateAction } from '../../layout/layout.actions';
import { store } from '../../state/store';
import { logger } from '../diagnostics/logger';

import ErrorFallback from './ErrorFallback';

interface IProps {
  readonly children: React.ReactNode;
}

const AppErrorBoundry = (props: IProps) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error: Error, info: ErrorInfo) => {
        logger.error('RENDER ERROR / APP CRASH! error:' + '\n\n' + info.componentStack, error);
      }}
      onReset={() => {
        store.dispatch(navigateAction(paths.landing));
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default AppErrorBoundry;
