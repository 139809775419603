export const GET_ORDER_HISTORY_PENDING = '[OrderHistory]/GET_ORDER_HISTORY_PENDING';
export const GET_ORDER_HISTORY_SUCCESS = '[OrderHistory]/GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_ERROR = '[OrderHistory]/GET_ORDER_HISTORY_ERROR';
export const GET_PACKING_LIST_FILE_PENDING = '[OrderHistory]/GET_PACKING_LIST_FILE_PENDING';
export const GET_PACKING_LIST_FILE_SUCCESS = '[OrderHistory]/GET_PACKING_LIST_FILE_SUCCESS';
export const GET_PACKING_LIST_FILE_ERROR = '[OrderHistory]/GET_PACKING_LIST_FILE_ERROR';
export const UPDATE_CUSTOMER_PO_FILE_PENDING = '[OrderHistory]/UPDATE_CUSTOMER_PO_FILE_PENDING';
export const UPDATE_CUSTOMER_PO_FILE_SUCCESS = '[OrderHistory]/UPDATE_CUSTOMER_PO_FILE_SUCCESS';
export const UPDATE_CUSTOMER_PO_FILE_ERROR = '[OrderHistory]/UPDATE_CUSTOMER_PO_FILE_ERROR';
export const UPDATE_CUSTOMER_PO_NUMBER_PENDING = '[OrderHistory]/UPDATE_CUSTOMER_PO_NUMBER_PENDING';
export const UPDATE_CUSTOMER_PO_NUMBER_SUCCESS = '[OrderHistory]/UPDATE_CUSTOMER_PO_NUMBER_SUCCESS';
export const UPDATE_CUSTOMER_PO_NUMBER_ERROR = '[OrderHistory]/UPDATE_CUSTOMER_PO_NUMBER_ERROR';
export const TOGGLE_CUSTOMER_PO_DIALOG = '[OrderHistory]/TOGGLE_CUSTOMER_PO_DIALOG';
export const TOGGLE_ORDER_DOCUMENTS_DIALOG = '[OrderHistory]/TOGGLE_ORDER_DOCUMENTS_DIALOG';
export const TOGGLE_ORDER_HISTORY_ISDOCUMENT_CHECKED = '[OrderHistory]/TOGGLE_ORDER_HISTORY_ISDOCUMENT_CHECKED';
export const TOGGLE_ALL_ORDER_HISTORY_ISDOCUMENT_CHECKED = '[OrderHistory]/TOGGLE_ALL_ORDER_HISTORY_ISDOCUMENT_CHECKED';
export const TOGGLE_ORDER_DOCUMENTS_DIALOG_MOBILE = '[OrderHistory]/TOGGLE_ORDER_DOCUMENTS_DIALOG_MOBILE';
export const DOWNLOAD_CHECKED_DOCUMENT_PENDING = '[OrderHistory]/DOWNLOAD_CHECKED_DOCUMENT_PENDING';
export const DOWNLOAD_CHECKED_DOCUMENT_SUCCES = '[OrderHistory]/DOWNLOAD_CHECKED_DOCUMENT_SUCCES';
export const DOWNLOAD_CHECKED_DOCUMENT_ERROR = '[OrderHistory]/DOWNLOAD_CHECKED_DOCUMENT_ERROR';
export const SORT = '[OrderHistory]/SORT';
export const SET_BEGIN_FILTER_DATE = '[OrderHistory]/SET_BEGIN_FILTER_DATE';
export const SET_END_FILTER_DATE = '[OrderHistory]/SET_END_FILTER_DATE';

export const SEARCH = '[OrderHistory]/SEARCH';
export const RESET_FILTERS = '[OrderHistory]/RESET_FILTERS';

export const TOGGLE_FILTER_DIALOG = '[OrderHistory]/TOGGLE_FILTER_DIALOG';
