import { NumericFormat } from 'react-number-format';
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';

import { IState } from '../../../state/store.state';
import { pricePrecision } from '../../app.constants';
import { cartTotalPriceSelector } from '../shopping.selectors';

const ShoppingCartFooterTotalPrice = () => {
  const totalPrice = useSelector((state: IState) => cartTotalPriceSelector(state));

  return (
    <Box margin="15px">
      <Grid container={true} direction="row">
        <Grid container={true} item={true} xs={6}>
          <Typography color="textPrimary" variant="subtitle2">
            Total Price:
          </Typography>
        </Grid>
        <Grid container={true} item={true} xs={6} justifyContent="flex-end" alignItems="flex-end">
          <Typography color="textPrimary" variant="subtitle2">
            <NumericFormat
              value={totalPrice}
              displayType={'text'}
              decimalScale={pricePrecision}
              fixedDecimalScale={true}
              prefix={'$'}
              thousandSeparator={true}
              // ps={'thousand'}
            />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShoppingCartFooterTotalPrice;
