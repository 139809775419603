import * as actionInterfaces from './order.history.actions.interfaces';
import {
  GET_ORDER_HISTORY_ERROR,
  GET_ORDER_HISTORY_PENDING,
  GET_ORDER_HISTORY_SUCCESS,
  TOGGLE_ORDER_DOCUMENTS_DIALOG,
  TOGGLE_ORDER_DOCUMENTS_DIALOG_MOBILE,
  UPDATE_CUSTOMER_PO_FILE_ERROR,
  UPDATE_CUSTOMER_PO_FILE_PENDING,
  UPDATE_CUSTOMER_PO_FILE_SUCCESS,
  UPDATE_CUSTOMER_PO_NUMBER_ERROR,
  UPDATE_CUSTOMER_PO_NUMBER_PENDING,
  UPDATE_CUSTOMER_PO_NUMBER_SUCCESS,
  TOGGLE_FILTER_DIALOG,
  SET_BEGIN_FILTER_DATE,
  SET_END_FILTER_DATE,
  SORT,
  RESET_FILTERS,
  SEARCH,
  TOGGLE_ORDER_HISTORY_ISDOCUMENT_CHECKED,
  TOGGLE_ALL_ORDER_HISTORY_ISDOCUMENT_CHECKED
} from './order.history.actions.constants';
import { IOrderHistory, IOrderHistoryState } from './order.history.state.interfaces';
import { ISortDirection } from './order.history.enums';
import {
  totalAmountColumnHeaderId,
  totalItemsColumnHeaderId,
  totalWeightColumnHeaderId
} from './order.history.constants';

export const initialState: IOrderHistoryState = {
  orders: [],
  showCustomerPurchaseOrderDialog: false,
  showOrderDocumentsDialog: false,
  showOrderDocumentsDialogMobile: false,
  orderDocumentDialogOrderId: -1,
  isUpdatePoPending: false,
  isOrderHistoryLoadPending: false,
  headers: [
    {
      id: 'expand',
      label: '',
      sortDirection: ISortDirection.NotSorted,
      sortable: false,
      sortKeyName: '',
      isNumeric: false
    },
    {
      id: 'orderNumber',
      label: 'Order Number',
      sortDirection: ISortDirection.NotSorted,
      sortable: true,
      sortKeyName: 'erpSalesOrderNumber',
      isNumeric: false
    },
    {
      id: 'orderDate',
      label: 'Order Date',
      sortDirection: ISortDirection.Decending,
      sortable: true,
      sortKeyName: 'createdOnDate',
      isNumeric: false
    },
    {
      id: 'orderStatus',
      label: 'Order Status',
      sortDirection: ISortDirection.NotSorted,
      sortable: true,
      sortKeyName: 'orderStatusId',
      isNumeric: false
    },
    {
      id: totalItemsColumnHeaderId,
      label: 'Total Items',
      sortDirection: ISortDirection.NotSorted,
      sortable: true,
      sortKeyName: totalItemsColumnHeaderId,
      isNumeric: true
    },
    {
      id: totalWeightColumnHeaderId,
      label: 'Total Weight Lbs',
      sortDirection: ISortDirection.NotSorted,
      sortable: true,
      sortKeyName: totalWeightColumnHeaderId,
      isNumeric: true
    },
    {
      id: totalAmountColumnHeaderId,
      label: 'Total Amount',
      sortDirection: ISortDirection.NotSorted,
      sortable: true,
      sortKeyName: totalAmountColumnHeaderId,
      isNumeric: true
    },
    {
      id: 'poDocUpload',
      label: 'PO Doc Upload',
      sortDirection: ISortDirection.NotSorted,
      sortable: false,
      sortKeyName: '',
      isNumeric: false
    },
    {
      id: 'poRefNumber',
      label: 'PO Ref Number',
      sortDirection: ISortDirection.NotSorted,
      sortable: true,
      sortKeyName: 'customerPoNumber',
      isNumeric: false
    },
    {
      id: 'documents',
      label: 'Documents',
      sortDirection: ISortDirection.NotSorted,
      sortable: false,
      sortKeyName: '',
      isNumeric: false
    }
  ],
  filters: {
    showFilterDialog: false,
    beginDate: undefined,
    endDate: undefined,
    isFiltered: false
  },
  searchText: ''
};
const handleGetOrderHistoryPending = (state: IOrderHistoryState): IOrderHistoryState => {
  return { ...state, isOrderHistoryLoadPending: true };
};
const handleGetOrderHistorySuccess = (
  state: IOrderHistoryState,
  action: actionInterfaces.IGetOrderHistorySuccess
): IOrderHistoryState => {
  return {
    ...state,
    orders: action.payload.map((o) => {
      return {
        ...o,
        documents: o.documents.map((d) => {
          return { ...d, isDocumentChecked: false };
        })
      };
    }),
    isOrderHistoryLoadPending: false
  };
};

const handleGetOrderHistoryError = (state: IOrderHistoryState): IOrderHistoryState => {
  return {
    ...state,
    isOrderHistoryLoadPending: false
  };
};

const handleOrderHistoryIsDocumentChecked = (
  state: IOrderHistoryState,
  action: actionInterfaces.IToggleOrderHistoryIsDocumentChecked
): IOrderHistoryState => {
  return {
    ...state,
    orders: state.orders.map((o) => {
      return {
        ...o,
        documents: o.documents.map((d) => {
          if (d.id === action.meta.documentId) {
            return { ...d, isDocumentChecked: !d.isDocumentChecked };
          }
          return d;
        })
      };
    })
  };
};

const handleAllOrderHistoryIsDocumentCheccked = (
  state: IOrderHistoryState,
  action: actionInterfaces.IToggleAllOrderHistoryIsDocumentChecked
): IOrderHistoryState => {
  return {
    ...state,
    orders: state.orders.map((o) => {
      return {
        ...o,
        documents: o.documents.map((d) => {
          return { ...d, isDocumentChecked: action.meta.checkedState };
        })
      };
    }),
    isOrderHistoryLoadPending: false
  };
};

const handleSort = (state: IOrderHistoryState, action: actionInterfaces.ISort): IOrderHistoryState => {
  const sortDirection = action.meta.isDescending ? ISortDirection.Decending : ISortDirection.Ascending;
  const headers = state.headers.map((header) => {
    if (header.id === action.meta.columnId) {
      return {
        ...header,
        sortDirection
      };
    } else {
      return { ...header, sortDirection: ISortDirection.NotSorted };
    }
  });

  const sortKeyName = String(headers.find((header) => header.id === action.meta.columnId)?.sortKeyName);
  const orders = getSortedOrders(state.orders, sortKeyName, sortDirection);

  return { ...state, headers, orders };
};

const getSortedOrders = (
  orderHistory: IOrderHistory[],
  sortKeyName: string,
  sortDirection: ISortDirection
): IOrderHistory[] => {
  return orderHistory.sort((first, second) => {
    if (sortKeyName === totalItemsColumnHeaderId) {
      return first.details.length > second.details.length && sortDirection === ISortDirection.Ascending ? 1 : -1;
    }
    if (sortKeyName === totalWeightColumnHeaderId) {
      const firstTotalNetWeight = first.details.reduce((total, next) => total + next.netWeight, 0);
      const secondTotalNetWeight = second.details.reduce((total, next) => total + next.netWeight, 0);
      return firstTotalNetWeight > secondTotalNetWeight && sortDirection === ISortDirection.Ascending ? 1 : -1;
    }
    if (sortKeyName === totalAmountColumnHeaderId) {
      const firstTotalNetWeight = first.details.reduce((total, next) => total + next.price * next.netWeight, 0);
      const secondTotalNetWeight = second.details.reduce((total, next) => total + next.price * next.netWeight, 0);
      return firstTotalNetWeight > secondTotalNetWeight && sortDirection === ISortDirection.Ascending ? 1 : -1;
    }
    return first[sortKeyName as keyof IOrderHistory] > second[sortKeyName as keyof IOrderHistory] &&
      sortDirection === ISortDirection.Ascending
      ? 1
      : -1;
  });
};

const orderHistoryReducer = (
  state: IOrderHistoryState = initialState,
  action:
    | actionInterfaces.IGetOrderHistoryPending
    | actionInterfaces.IGetOrderHistorySuccess
    | actionInterfaces.IGetOrderHistoryError
    | actionInterfaces.IToggleOrderDocumentsDialog
    | actionInterfaces.IToggleOrderHistoryIsDocumentChecked
    | actionInterfaces.IToggleAllOrderHistoryIsDocumentChecked
    | actionInterfaces.IToggleOrderDocumentsDialogMobile
    | actionInterfaces.IUpdateCustomerPoFileError
    | actionInterfaces.IUpdateCustomerPoFilePending
    | actionInterfaces.IUpdateCustomerPoFileSuccess
    | actionInterfaces.IUpdateCustomerPoNumberError
    | actionInterfaces.IUpdateCustomerPoNumberPending
    | actionInterfaces.IUpdateCustomerPoNumberSuccess
    | actionInterfaces.IToggleFilterDialog
    | actionInterfaces.ISetBeginDateFilter
    | actionInterfaces.ISetEndDateFilter
    | actionInterfaces.ISort
    | actionInterfaces.IResetFilters
    | actionInterfaces.ISearch
): IOrderHistoryState => {
  switch (action.type) {
    case GET_ORDER_HISTORY_PENDING:
      return handleGetOrderHistoryPending(state);
    case GET_ORDER_HISTORY_SUCCESS:
      return handleGetOrderHistorySuccess(state, action);
    case GET_ORDER_HISTORY_ERROR:
      return handleGetOrderHistoryError(state);
    case TOGGLE_ORDER_DOCUMENTS_DIALOG:
      return {
        ...state,
        showOrderDocumentsDialog: !state.showOrderDocumentsDialog,
        orderDocumentDialogOrderId: action.meta.orderId
      };
    case TOGGLE_ORDER_DOCUMENTS_DIALOG_MOBILE:
      return {
        ...state,
        showOrderDocumentsDialogMobile: !state.showOrderDocumentsDialogMobile
      };
    case TOGGLE_ORDER_HISTORY_ISDOCUMENT_CHECKED:
      return handleOrderHistoryIsDocumentChecked(state, action);
    case TOGGLE_ALL_ORDER_HISTORY_ISDOCUMENT_CHECKED:
      return handleAllOrderHistoryIsDocumentCheccked(state, action);
    case UPDATE_CUSTOMER_PO_FILE_PENDING:
      return { ...state, isUpdatePoPending: true };
    case UPDATE_CUSTOMER_PO_FILE_SUCCESS:
      return { ...state, isUpdatePoPending: false };
    case UPDATE_CUSTOMER_PO_FILE_ERROR:
      return { ...state, isUpdatePoPending: false };
    case UPDATE_CUSTOMER_PO_NUMBER_PENDING:
      return { ...state, isUpdatePoPending: true };
    case UPDATE_CUSTOMER_PO_NUMBER_SUCCESS:
      return { ...state, isUpdatePoPending: false };
    case UPDATE_CUSTOMER_PO_NUMBER_ERROR:
      return { ...state, isUpdatePoPending: false };
    case TOGGLE_FILTER_DIALOG:
      return { ...state, filters: { ...state.filters, showFilterDialog: !state.filters.showFilterDialog } };
    case SET_BEGIN_FILTER_DATE:
      return { ...state, filters: { ...state.filters, beginDate: action.meta.beginDate, isFiltered: true } };
    case SET_END_FILTER_DATE:
      return { ...state, filters: { ...state.filters, endDate: action.meta.endDate, isFiltered: true } };
    case RESET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          beginDate: initialState.filters.beginDate,
          endDate: initialState.filters.endDate,
          isFiltered: false
        }
      };
    case SEARCH:
      return { ...state, searchText: action.meta.searchText };
    case SORT:
      return handleSort(state, action);
    default:
      return state;
  }
};

export default orderHistoryReducer;
