import { SagaIterator } from 'redux-saga';
import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';

import saveAs from 'file-saver';
import { getToken } from '../../core/auth/authentication.msal.api';
import { authWait } from '../../core/auth/authentication.saga';
import { logger } from '../../core/diagnostics/logger';
import { toggleAlertDialogAction } from '../Notification/notification.actions';
import { IAlertDialogOptions } from '../Notification/notification.state.interface';

import { OrderDocumentType } from '../OrderHistory/order.history.enums';
import { getCertificatePendingAction } from '../Product/product.actions';
import { DOWNLOAD_FILE_PENDING } from './fileDownload.actions.constants';
import { downloadFileErrorAction, downloadFileSucessAction } from './fileDownload.actions';
import { downloadOrderDocument } from './fileDownlaod.api';
import { IDownloadFilePending } from './fileDownload.actions.interfaces';

const fileName = 'fileDownload.saga.ts';

function* fileDownloadPending(action: IDownloadFilePending): Generator<StrictEffect, any, any> {
  try {
    if (action.meta.orderDocumentType === OrderDocumentType.Certification) {
      yield put(getCertificatePendingAction(action.meta.url));
      yield put(downloadFileSucessAction(undefined));
    } else {
      yield call(authWait);
      const tokenResponse = yield call(getToken);
      const response = yield call(downloadOrderDocument, action.meta.url, tokenResponse);
      saveAs(response, action.meta.fileName);
      const objectURL = URL.createObjectURL(response);
      window.open(objectURL);
      yield put(downloadFileSucessAction(response));
    }
  } catch (e) {
    logger.error('fileDownloadPending', e as Error, fileName);
    const dialogOptions: IAlertDialogOptions = {
      title: 'Error Downloading Order Document',
      message: 'Please Contact Your Metal Exchange Sales Representative If Issue Persists.'
    };

    yield put(toggleAlertDialogAction(true, dialogOptions));
    yield put(downloadFileErrorAction());
  }
}

function* fileDownloadSaga(): SagaIterator {
  yield takeLatest(DOWNLOAD_FILE_PENDING, fileDownloadPending);
}

export default fileDownloadSaga;
