export const GET_CUSTOMER_INFO_PENDING = '[Customer]/GET_CUSTOMER_INFO_PENDING';

export const GET_CUSTOMER_INFO_SUCCESS = '[Customer]/GET_CUSTOMER_INFO_SUCCESS';

export const GET_CUSTOMER_INFO_ERROR = '[Customer]/GET_CUSTOMER_INFO_ERROR';

export const GET_CUSTOMER_SUPER_USER_INFO_PENDING = '[Customer]/GET_CUSTOMER_SUPERUSER_INFO_PENDING';

export const GET_CUSTOMER_SUPER_USER_INFO_SUCCESS = '[Customer]/GET_CUSTOMER_SUPERUSER_INFO_SUCCESS';

export const GET_CUSTOMER_SUPER_USER_INFO_ERROR = '[Customer]/GET_CUSTOMER_SUPERUSER_INFO_ERROR';

export const SET_CUSTOMER_FOR_SUPER_USER = '[Customer]/SET_CUSTOMER_FOR_SUPER_USER';

export const TOGGLE_IMPERSONATION_SELECT_DIALOG = '[Customer]/TOGGLE_IMPERSONATION_SELECT_DIALOG';

export const IMPERSONATE_PENDING = '[Customer]/IMPERSONATE_PENDING';

export const IMPERSONATE_SUCCESS = '[Customer]/IMPERSONATE_SUCCESS';

export const IMPERSONATE_ERROR = '[Customer]/IMPERSONATE_ERROR';
