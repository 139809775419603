export enum OrderStatus {
  Invalid = 0,
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Completed = 4,
  Error = 5,
  FailedOrderValidation = 6,
  Cancelled = 7,
  'Pending PO' = 8,
  'Pending MEC Approval' = 9, // #MX-BRANDING - Can this be changed without breaking?  Is it user-facing?
  'Pending Pick Up' = 10,
  'Picked Up / Invoiced' = 11
}

export enum OrderDetailstatus {
  Pending = 1,
  Completed = 2
}
