import { Dispatch } from 'react';

import { filterSelectionPendingAction } from './filter.actions';
import { IFilterSelection } from './filter.state.interfaces';

export const dispatchFilter = (name: string, value: any) => (dispatch: Dispatch<any>) => {
  const filterChange: IFilterSelection = {
    name,
    value
  };

  dispatch(filterSelectionPendingAction(filterChange));
};

export const isObjectArray = (array: any): boolean => {
  return Object.prototype.toString.call(array) === '[object Array]';
};
