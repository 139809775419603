export const SET_CUSTOMER_PURCHASE_ORDER_NUMBER = '[CustomerPo]/SET_CUSTOMER_PURCHASE_ORDER_NUMBER';

export const SET_CUSTOMER_PURCHASE_ORDER_FILE = '[CustomerPo]/SET_CUSTOMER_PURCHASE_ORDER_FILE';

export const RESET_CUSTOMER_PURCHASE_ORDER_FILE = '[CustomerPo]/RESET_CUSTOMER_PURCHASE_ORDER_FILE';

export const RESET_CUSTOMER_PURCHASE_ORDER_NUMBER = '[CustomerPo]/RESET_CUSTOMER_PURCHASE_ORDER_NUMBER';

export const FILE_CAPTURE_PENDING = '[CustomerPo]/FILE_CAPTURE_PENDING';

export const FILE_CAPTURE_SUCCESS = '[CustomerPo]/FILE_CAPTURE_SUCCESS';

export const FILE_CAPTURE_ERROR = '[CustomerPo]/FILE_CAPTURE_ERROR';
