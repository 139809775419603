import { createSelector } from 'reselect';

import { IState } from '../../state/store.state';

import { AuthorizationStatus } from './authorization.enum';
import { IAuthorizationState } from './authorization.state.interface';

const authorization = (state: IState) => state.authorization;

export const groupsSelector = createSelector([authorization], (auth: IAuthorizationState) => auth.groups);

export const isAuthorizedForSalesPortalSelector = createSelector(
  [authorization],
  (authorization: IAuthorizationState) => authorization.authorizationStatus == AuthorizationStatus.Authorized
);

export const authorizationStatusSelector = createSelector(
  [authorization],
  (authorization: IAuthorizationState) => authorization.authorizationStatus
);
