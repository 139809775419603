import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLeading } from 'redux-saga/effects';

import { AccountInfo } from '@azure/msal-common';

import { getAccount, logout } from '../../core/auth/authentication.msal.api';
import { logger } from '../../core/diagnostics/logger';
import { productLoadPendingAction } from '../Product/product.actions';
import { getCustomerInfoPendingAction } from '../Customer/customer.actions';
import { getOrderHistoryPendingAction } from '../OrderHistory/order.history.actions';
import { shoppingStartupAction } from '../Shopping/shopping.actions';
import { toggleAlertDialogAction } from '../Notification/notification.actions';
import { IAlertDialogOptions } from '../Notification/notification.state.interface';
import { LOGIN_SUCCESS } from '../../core/auth/authentication.actions.constants';

import { getBulletinItemsPendingAction } from '../Main/bulletin.actions';

import { isRegistrationSelector } from '../../core/auth/authentication.selectors';
import { CreateLoginEvent, SetLoginUserInfo } from '../Analytics/v2/analytics.events';
import { getCustomerInformation } from '../Customer/customer.api';
import { ICustomerInfo } from '../Customer/customer.state.interface';
import { googleAnalyticsPropertyNames, LogInEventNames } from '../Analytics/v2/analytics.enum';
import { getAuthorizationError, getAuthorizationPending, getAuthorizationSuccess } from './authorization.actions';
import { getUserGroups } from './authorization.api';
import { AuthorizationStatus } from './authorization.enum';
import { IAadGroup } from './authorization.state.interface';
import { authorizationStatusSelector } from './authorization.selectors';

const fileName = 'authorization.saga.ts';

function* authorize() {
  try {
    const isRegistration: boolean = yield select(isRegistrationSelector);

    if (isRegistration) {
      return;
    }

    const authorizationStatus: AuthorizationStatus = yield select(authorizationStatusSelector);

    if (authorizationStatus === AuthorizationStatus.InitialState) {
      yield put(getAuthorizationPending());

      const userAccount: AccountInfo | undefined = yield call(getAccount);

      if (!userAccount) {
        throw new Error('No account available');
      }

      const groups: IAadGroup[] = yield call(getUserGroups, userAccount.localAccountId);

      yield put(getAuthorizationSuccess(AuthorizationStatus.Authorized, groups));

      if (groups.length < 1) {
        yield alertNoSecurity();
      }

      const customerInfo: ICustomerInfo = yield call(getCustomerInformation, userAccount.localAccountId);

      SetLoginUserInfo(googleAnalyticsPropertyNames.userProperties, {
        tcp_customer_name: customerInfo.company,
        tcp_user_id: userAccount.localAccountId,
        tcp_user_name: userAccount.username
      });

      CreateLoginEvent(LogInEventNames.login, {
        tcp_customer_name: customerInfo.company,
        tcp_user_id: userAccount.localAccountId,
        tcp_user_name: userAccount.username
      });
      yield put(getOrderHistoryPendingAction());
      yield put(productLoadPendingAction());
      yield put(getCustomerInfoPendingAction());
      yield put(shoppingStartupAction());
      yield put(getBulletinItemsPendingAction());
    } else {
      logger.info('No startup....');
    }
  } catch (e) {
    logger.error('getUserSecurity', e as Error, fileName);
    yield put(getAuthorizationError());
    yield alertNoSecurity();
  }
}

function* alertNoSecurity(): SagaIterator {
  const dialogOptions: IAlertDialogOptions = {
    message:
      'Your account does not currently have permission to access this site.  If you have recently registered with us, we will notify you as soon as the account is ready.  Thank you.',
    onClose: logout
  };

  yield put(toggleAlertDialogAction(true, dialogOptions));
}

function* authorizationSaga(): SagaIterator {
  yield takeLeading(LOGIN_SUCCESS, authorize);
}

export default authorizationSaga;
