import { createTheme } from '@mui/material/styles';

/*
2023 Branding Color Palette:

#006386 - MXH Primary Blue                  primary.main
#33829e - MXH Primary Blue, 80%             primary.light (does not appear to be used via palette)
                                            
#193062 - MXH Secondary Dark Blue           secondary.main
#475981 - MXH Secondary Dark Blue, 80%      secondary.light
                                            
#00b5d7 - MXH Secondary Light Blue          info.main
#33c4df - MXH Secondary Light Blue, 80%     info.light (does not appear to be used via palette)

#3db072 - MXH Secondary Green               success.main (does not appear to be used via palette)
#64c08e - MXH Secondary Green, 80%          success.light (does not appear to be used via palette)
                                            
#c3d534 - MXH Accent Green                  warning.main
                                            
#47555d - Neutral Gray                      text.primary (might not be used via palette?), warning.contrastText (does not appear to be used via palette)
#6c777d - Neutral Gray 80%                  text.secondary (might not be used via palette?)
#91999e - Neutral Gray 60%                  text.disabled (might not be used via palette?)
                                            
#ffffff - White                             all contrastText values except warning
                                            
#d62246 - Non-MXH Color*                    error.main (does not appear to be used via palette)

* This red was generated by https://coolors.co by locking MXH Primary Blue, Secondary Dark Blue,
  Secondary Green and Accent Green, then clicking until it generated a suitable red for
  use as an error color.

*/

export const salesPortalTheme = createTheme({
  typography: {
    fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Tahoma, Arial, sans-serif'
  },
  palette: {
    primary: {
      main: '#006386',
      light: '#33829e',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#193062',
      light: '#475981',
      contrastText: '#ffffff'
    },
    info: {
      main: '#00b5d7',
      light: '#33c4df',
      contrastText: '#ffffff'
    },
    success: {
      main: '#3db072',
      light: '#64c08e',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#c3d534',
      contrastText: '#47555d'
    },
    error: {
      main: '#d62246',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#47555d',
      secondary: '#6c777d',
      disabled: '#91999e'
    }
  }
});
