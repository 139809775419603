import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OutlinedInput } from '@mui/material';

import debounce from 'lodash.debounce';
import { IState } from '../../../../state/store.state';
import { setCusomerPoNumberAction } from '../../../CustomerOrderInfo/customerPo.actions';
import {
  customerPoNumberIsErrorSelector,
  customerPoNumberSelector
} from '../../../CustomerOrderInfo/customerPo.selectors';

interface IProps {
  readonly orderId: number;
}

const ShoppingCartCustomerPoNumber = (props: IProps) => {
  const dispatch = useDispatch();
  const { orderId } = props;
  const [controlled, setController] = useState('');

  const isInError = useSelector((state: IState) => customerPoNumberIsErrorSelector(state));
  const customerPoNumberInfo = useSelector((state: IState) => customerPoNumberSelector(state));

  const updateWithChange = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(setCusomerPoNumberAction(orderId, value));
      }, 25),
    []
  );

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setController(value);
    updateWithChange(value);
  };

  return (
    <OutlinedInput
      type={'text'}
      error={isInError}
      required={true}
      value={controlled || customerPoNumberInfo.customerPoNumber}
      onChange={handleChange()}
      margin="dense"
      fullWidth={true}
    />
  );
};

export default ShoppingCartCustomerPoNumber;
