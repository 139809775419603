const paths = {
  landing: '/',
  contactUs: '/contact-us',
  legal: '/legal',
  product: '/product',
  orderHistory: '/order-history',

  register: '/register'
};

export default paths;
