import React from 'react';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  }
}));

interface IProps {
  readonly inputControlId: string;
  readonly handleCapture: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomerPoUploadInput = (props: IProps) => {
  const classes = useStyles();

  return (
    <input className={classes.input} id={props.inputControlId} multiple type="file" onChange={props.handleCapture} />
  );
};

export default CustomerPoUploadInput;
