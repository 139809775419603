import { NumericFormat } from 'react-number-format';
import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';

import { IState } from '../../../state/store.state';
import { cartTotalWeightSelector } from '../shopping.selectors';

const ShoppingCartFooterTotalWeight = () => {
  const totalWeight = useSelector((state: IState) => cartTotalWeightSelector(state));

  return (
    <Box margin="15px">
      <Grid container={true} direction="row">
        <Grid container={true} item={true} xs={6}>
          <Typography color="textPrimary" variant="subtitle2">
            Total Weight:
          </Typography>
        </Grid>
        <Grid container={true} item={true} xs={6} justifyContent="flex-end" alignItems="flex-end">
          <Typography color="textPrimary" variant="subtitle2">
            <NumericFormat
              value={totalWeight}
              displayType={'text'}
              suffix={' lbs'}
              thousandSeparator={true}
              thousandsGroupStyle={'thousand'}
            />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShoppingCartFooterTotalWeight;
