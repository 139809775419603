export interface ILoginEventInfo {
  readonly tcp_customer_name: string;
  readonly tcp_user_name: string;
  readonly tcp_user_id: string;
  //readonly user_id: string;
}

export interface IFilterSearchNoResultEventInfo {
  readonly search_term: string;
}

export interface IButtonClickEventInfo {
  readonly content_type: string;
  readonly item_id: string;
}

export interface ICartEventInfo {
  readonly currency: string;
  readonly value: number;
  readonly items: ICartItem[];
}

export interface ICartItem {
  readonly item_id: string;
  readonly item_name: string;
  readonly price: number;
  readonly quantity: number;
}

export interface IProductSearchNoResultsModel {
  readonly userAzureADId?: string | null;
  readonly emailAddress?: string | null;
  readonly companyName: string | null;
  readonly superUserAzureId?: string | null;
  readonly superUserEmailAddress?: string | null;
  readonly isInternalCompanyEmployee: boolean;
  readonly alloy: string | null;
  readonly temper: string | null;
  readonly gauge: string | null;
  readonly width: string | null;
  readonly length: string | null;
  readonly finish: string | null;
  readonly warehouseName: string | undefined;
  readonly castNumber: string | null;
}

export enum CartActivityTypeEnum {
  AddToCart = 1,
  RemoveFromCart = 2
}

export interface IUserCartActivityMdoel {
  readonly userAzureADId?: string | null;
  readonly emailAddress?: string | null;
  readonly companyName?: string | null;
  readonly superUserAzureId?: string | null;
  readonly superUserEmailAddress?: string | null;
  readonly isInternalCompanyEmployee: boolean;
  readonly productIdentifier: string;
  readonly cartActivityTypeId: CartActivityTypeEnum;
  readonly cartActivityDate: Date;
}
