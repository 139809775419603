import React from 'react';

import CustomerPoUploadAttach from './CustomerPoUploadAttach';
import CustomerPoUploadInput from './CustomerPoUploadInput';
import CustomerPoUploadViewEdit from './CustomerPoUploadViewEdit';

interface IProps {
  readonly orderId: number;
  readonly fileName: string | null | undefined;
  readonly orderDocumentLocation?: string;
  readonly handleCapture: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly required: boolean;
}

const CustomerPoUpload = (props: IProps) => {
  const { fileName, orderId, handleCapture, orderDocumentLocation } = props;
  const inputControlId = 'contained-button-file' + props.orderId;

  return (
    <>
      <CustomerPoUploadInput inputControlId={inputControlId} handleCapture={handleCapture} />
      {!fileName ? (
        <>
          <CustomerPoUploadAttach
            required={props.required}
            inputControlId={inputControlId}
            orderId={orderId}
            fileName={fileName}
          />
        </>
      ) : (
        <>
          <CustomerPoUploadViewEdit inputControlId={inputControlId} fileName={fileName} url={orderDocumentLocation} />
        </>
      )}
    </>
  );
};

export default CustomerPoUpload;
