import React from 'react';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, IconButton, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { shoppingRemoveAllFromCartPendingAction } from '../shopping.actions';
import { ICartItemModel } from '../shopping.state.interface';

interface IProps {
  readonly cartItems: ICartItemModel[];
  readonly handleClose: () => void;
  readonly isCheckoutPending: boolean;
}

const ShoppingCartHeader = (props: IProps) => {
  const dispatch = useDispatch();
  const shoppingCartClasses = useStyles();

  const handleRemoveAllItems = () => () => {
    dispatch(shoppingRemoveAllFromCartPendingAction(props.cartItems));
  };

  return (
    <AppBar position="sticky" color="inherit">
      <Box flexGrow={1}>
        <Box margin="15px" display="flex" flex="1" flexDirection="row">
          <Box flex=".9" alignItems="flex-start">
            <Typography className={shoppingCartClasses.header} variant="h6">
              My Cart
            </Typography>
            {props.cartItems.length > 0 && !props.isCheckoutPending ? (
              <Typography
                color="secondary"
                className={shoppingCartClasses.clearAll}
                variant="caption"
                onClick={handleRemoveAllItems()}
              >
                Clear All
              </Typography>
            ) : (
              <div />
            )}
          </Box>
          <Box flex=".1" alignItems="flex-end">
            <IconButton edge="start" color="inherit" aria-label="close" onClick={props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: 'bolder',
      color: theme.palette.primary.main,
      padding: '5px'
    },
    clearAll: {
      fontWeight: 'bolder',
      padding: '5px',
      cursor: 'pointer'
    }
  })
);

export default ShoppingCartHeader;
