import AlertType from './notification.enum';
import { CLOSE_NOTIFICATION, OPEN_NOTIFICATION, TOGGLE_ALERT_DIALOG } from './notification.actions.constants';
import { ICloseNotification, IOpenNotificatiion, IToggleAlertDialog } from './notification.actions.interface';
import { INotificationState } from './notification.state.interface';

export const initialState: INotificationState = {
  open: false,
  message: '',
  alertType: AlertType.Success,
  displayTime: 0,
  showAlertDialog: false,
  alertDialogOptions: {}
};

const toggleAlertDialog = (state: INotificationState, action: IToggleAlertDialog): INotificationState => {
  return {
    ...state,
    showAlertDialog: action.meta.showAlertDialog,
    alertDialogOptions: action.meta.alertDialogOptions
  };
};

const notificationReducer = (
  state: INotificationState = initialState,
  action: IOpenNotificatiion | ICloseNotification | IToggleAlertDialog
): INotificationState => {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return {
        ...state,
        open: true,
        message: action.meta.message,
        alertType: action.meta.alertType,
        displayTime: action.meta.displayTime
      };
    case CLOSE_NOTIFICATION:
      return { ...state, open: false };
    case TOGGLE_ALERT_DIALOG:
      return toggleAlertDialog(state, action);
    default:
      return state;
  }
};

export default notificationReducer;
