export const appName = 'Metal Exchange Ecommerce';

export const processConfig = {
  logLevel: process.env.REACT_APP_LOG_LEVEL ? process.env.REACT_APP_LOG_LEVEL : 'trace',
  aadGroups: {
    salesPortalAadGroup: process.env.REACT_APP_SALES_PORTAL_USER_GROUP
      ? process.env.REACT_APP_SALES_PORTAL_USER_GROUP
      : '',
    salesPortalSuperUserAadGroup: process.env.REACT_APP_SALES_PORTAL_SUPER_USER_GROUP || ''
  },
  auth: {
    logNonErrorAuth: process.env.REACT_APP_LOG_AUTH_NON_ERROR
      ? process.env.REACT_APP_LOG_AUTH_NON_ERROR === '1'
      : false,
    msal: {
      clientId: process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : '',
      knownAuthority: process.env.REACT_APP_KNOWN_AUTHORITY ? process.env.REACT_APP_KNOWN_AUTHORITY : '',
      redirectUrl: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
      postRedirectUrl: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
      authorityPassword: `${process.env.REACT_APP_AUTHORITY_BASE}${process.env.REACT_APP_USER_FLOW_PASSWORD}`,
      authorityLogin: `${process.env.REACT_APP_AUTHORITY_BASE}${process.env.REACT_APP_USER_FLOW_LOGIN}`,
      authorityRegister: `${process.env.REACT_APP_AUTHORITY_BASE}${process.env.REACT_APP_USER_FLOW_REGISTER}`,
      authorityProfile: `${process.env.REACT_APP_AUTHORITY_BASE}${process.env.REACT_APP_USER_FLOW_PROFILE}`,
      userFlowRegister: process.env.REACT_APP_USER_FLOW_REGISTER,
      userFlowPassword: process.env.REACT_APP_USER_FLOW_PASSWORD,
      loginScope: process.env.REACT_APP_LOGIN_SCOPE,
      passwordResetUri: `${process.env.REACT_APP_AUTHORITY_BASE}oauth2/v2.0/authorize?p=${process.env.REACT_APP_USER_FLOW_PASSWORD}&client_id=${process.env.REACT_APP_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI}&scope=openid&response_type=id_token&prompt=login`
    }
  },
  baseGatewayUrl: process.env.REACT_APP_GATEWAY_URL,
  claimsPolicyUrl: process.env.REACT_APP_CLAIMS_POLICY_URL,
  environment: process.env.NODE_ENV,
  googleAnalyticsTrackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID : '',
  intervals: {
    shoppingCartMonitorInterval: process.env.REACT_APP_SHOPPING_CART_MONITOR_INTERVAL_MS
      ? parseInt(process.env.REACT_APP_SHOPPING_CART_MONITOR_INTERVAL_MS, 10)
      : 600000,

    shoppingCartExpirationInterval: process.env.REACT_APP_SHOPPING_CART_EXPIRATION_CHECK_INTERVAL_MS
      ? parseInt(process.env.REACT_APP_SHOPPING_CART_EXPIRATION_CHECK_INTERVAL_MS, 10)
      : 600000
  }
};
