import { SagaIterator } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { authWait, getUserId } from '../../core/auth/authentication.saga';
import { logger } from '../../core/diagnostics/logger';
import { processConfig } from '../../environment.constants';
import { groupsSelector } from '../Authorization/authorization.selectors';
import { IAadGroup } from '../Authorization/authorization.state.interface';
import { getOrderHistoryPendingAction } from '../OrderHistory/order.history.actions';
import { GET_ORDER_HISTORY_SUCCESS } from '../OrderHistory/order.history.actions.constants';
import { productLoadPendingAction } from '../Product/product.actions';
import { PRODUCT_LOAD_SUCCESS } from '../Product/product.actions.constants';
import { shoppingGetCartPendingAction } from '../Shopping/shopping.actions';
import { SHOPPING_GET_CART_SUCCESS } from '../Shopping/shopping.actions.constants';

import {
  getCustomerInfoErrorAction,
  getCustomerInfoSuccessAction,
  getCustomerSuperUserInfoSuccessAction,
  impersonateErrorAction,
  impersonateSuccessAction
} from './customer.actions';
import { GET_CUSTOMER_INFO_PENDING, IMPERSONATE_PENDING } from './customer.actions.constants';
import { getCustomerInformation, getCustomerInformationSuperUseer } from './customer.api';
import { ICustomerImpersonationInfo, ICustomerInfo } from './customer.state.interface';

const fileName = 'customer.saga.ts';

function* getCustomerInfoPending() {
  try {
    yield call(authWait);
    const userId: string = yield call(getUserId);
    const result: ICustomerInfo = yield call(getCustomerInformation, userId);
    const customerInfo: ICustomerInfo = { ...result, userId };

    yield put(getCustomerInfoSuccessAction(customerInfo));

    const userGroups: IAadGroup[] | null = yield select(groupsSelector);
    if (
      userGroups &&
      userGroups.filter((f) => f.guidId === processConfig.aadGroups.salesPortalSuperUserAadGroup).length > 0
    ) {
      const superUserInfo: ICustomerImpersonationInfo[] = yield call(getCustomerInformationSuperUseer);
      yield put(getCustomerSuperUserInfoSuccessAction(superUserInfo));
    }
  } catch (e) {
    logger.error('getCustomerInfoPending', e as Error, fileName);

    yield put(getCustomerInfoErrorAction());
  }
}

function* handleImpersonatePending() {
  try {
    yield all([
      put(shoppingGetCartPendingAction()),
      put(productLoadPendingAction()),
      put(getOrderHistoryPendingAction())
    ]);
    yield all([take(SHOPPING_GET_CART_SUCCESS), take(GET_ORDER_HISTORY_SUCCESS), take(PRODUCT_LOAD_SUCCESS)]);
    yield put(impersonateSuccessAction());
  } catch (e) {
    logger.error('handleImpersonatePending', e as Error, fileName);

    yield put(impersonateErrorAction());
  }
}

function* customerSaga(): SagaIterator {
  yield takeLatest(GET_CUSTOMER_INFO_PENDING, getCustomerInfoPending);
  yield takeLatest(IMPERSONATE_PENDING, handleImpersonatePending);
}

export default customerSaga;
