import { saveAs } from 'file-saver';
import { SagaIterator } from 'redux-saga';
import { StrictEffect, call, put, select, takeLatest } from 'redux-saga/effects';

import { getToken } from '../../core/auth/authentication.msal.api';
import { authWait, getUserId } from '../../core/auth/authentication.saga';
import { logger } from '../../core/diagnostics/logger';
import { SearchEventNames } from '../Analytics/v2/analytics.enum';
import { CreateFilterSearchNoResultEvent } from '../Analytics/v2/analytics.events';
import { impersonatedUserIdSelector } from '../Customer/customer.selectors';
import { FILTER_RESET, FILTER_SELECTION_SUCCESS } from '../Filter/filter.actions.constants';
import { IFilterCriteria } from '../Filter/filter.state.interfaces';
import { getFilterCriteriaSelector } from '../Filter/filters.selectors';
import { notifyErrorAction, toggleAlertDialogAction } from '../Notification/notification.actions';
import { IAlertDialogOptions } from '../Notification/notification.state.interface';

import { insertProductSearchNoResults } from '../Analytics/analytics.api';
import { productSearchNoResultsModelSelector } from '../Analytics/analytics.selectors';
import {
  getCertificateErrorAction,
  getCertificateSuccessAction,
  isDefaultSortAction,
  productsLoadErrorAction,
  productsLoadSuccessAction,
  setFilterPageAction
} from './product.actions';
import { GET_CERTIFICATE_PENDING, PRODUCT_LOAD_PENDING } from './product.actions.constants';
import { IGetCertifactePending } from './product.actions.interfaces';
import { getCertificate, getProducts } from './product.api';
import { IProduct } from './product.state.interfaces';

const fileName = 'product.saga.ts';

function* productLoadPending(): Generator<StrictEffect, any, any> {
  try {
    yield call(authWait);

    const impersonatedUserId: string = yield select(impersonatedUserIdSelector);
    if (impersonatedUserId) {
      logger.info('using impersonated user for filters');
    }

    const filterCriteria: IFilterCriteria = yield select(getFilterCriteriaSelector);

    if (impersonatedUserId) {
      filterCriteria.userId = impersonatedUserId;
    } else {
      if (filterCriteria.userId === '') {
        filterCriteria.userId = yield call(getUserId);
      }
    }
    const response = yield call(getProducts, filterCriteria);

    const products: IProduct[] = response.payload;

    yield put(productsLoadSuccessAction(response.payload));
    yield put(isDefaultSortAction(true));
    yield put(setFilterPageAction(0));

    if (products.length === 0) {
      const insertProductSearchNoResultsModel = yield select(productSearchNoResultsModelSelector);
      yield call(insertProductSearchNoResults, insertProductSearchNoResultsModel);
      delete filterCriteria.userId;
      CreateFilterSearchNoResultEvent(SearchEventNames.search, { search_term: JSON.stringify(filterCriteria) });
    }
  } catch (e) {
    logger.error('productLoadPending', e as Error, fileName);
    yield put(
      notifyErrorAction(
        e as Error,
        'We are having trouble retrieving our inventory for the current selection',
        1000,
        false
      )
    );
    yield put(productsLoadErrorAction());
  }
}

function* getCertificatePending(action: IGetCertifactePending): Generator<StrictEffect, any, any> {
  try {
    yield call(authWait);
    const tokenResponse = yield call(getToken);
    const response = yield call(getCertificate, action.meta.url, tokenResponse);
    saveAs(response.payload, 'cert.pdf');
    const objectURL = URL.createObjectURL(response.payload);
    window.open(objectURL);
    yield put(getCertificateSuccessAction(response.payload));
  } catch (e) {
    logger.error('getCertificatePending', e as Error, fileName);

    const dialogOptions: IAlertDialogOptions = {
      title: 'Error Downloading Certificate',
      message: 'Please Contact Your MX Sales Representative If Issue Persists.'
    };

    yield put(toggleAlertDialogAction(true, dialogOptions));
    yield put(getCertificateErrorAction());
  }
}

function* productSaga(): SagaIterator {
  yield takeLatest(PRODUCT_LOAD_PENDING, productLoadPending);
  yield takeLatest(FILTER_SELECTION_SUCCESS, productLoadPending);
  yield takeLatest(FILTER_RESET, productLoadPending);
  yield takeLatest(GET_CERTIFICATE_PENDING, getCertificatePending);
}

export default productSaga;
