export const PRODUCT_LOAD_PENDING = '[Product]/PRODUCT_LOAD_PENDING';
export const PRODUCT_LOAD_SUCCESS = '[Product]/PRODUCT_LOAD_SUCCESS';
export const PRODUCT_LOAD_ERROR = '[Product]/PRODUCT_LOAD_ERROR';
export const PRODUCT_SELECT = '[Product]/PRODUCT_SELECT';
export const GET_CERTIFICATE_PENDING = '[Product/GET_CERTIFICATE_PENDING';
export const GET_CERTIFICATE_SUCCESS = '[Product/GET_CERTIFICATE_SUCCESS';
export const GET_CERTIFICATE_ERROR = '[Product/GET_CERTIFICATE_ERROR';
export const PRODUCT_DEFAULT_SORT_ORDER = '[Product]/PRODUCT_DEFAULT_SORT_ORDER';
export const SET_FILTER_PAGE = '[Product]/SET_FILTER_PAGE';
export const SET_ROWS_PER_PAGE = '[Product]/SET_ROWS_PER_PAGE';
export const SET_SORT = '[Product]/SET_SORT';
