import { call, put, takeLatest } from 'redux-saga/effects';

import { authWait } from '../../core/auth/authentication.saga';
import { logger } from '../../core/diagnostics/logger';

import { getBulletinItemsSuccessAction } from './bulletin.actions';
import { GET_BULLETIN_ITEMS_PENDING } from './bulletin.actions.constants';
import { getBulletinItems } from './bulletin.api';
import { IBulletinApiResponse } from './bulletin.state.interfaces';

const fileName = 'bulletin.saga.ts';

function* getBulletinItemsPending() {
  try {
    yield call(authWait);
    const bulletinApiResponse: IBulletinApiResponse = yield call(getBulletinItems);
    yield put(getBulletinItemsSuccessAction(bulletinApiResponse.items));
  } catch (e) {
    logger.error('getbulletinItemsPending', e as Error, fileName);
  }
}

function* bulletinSaga() {
  yield takeLatest(GET_BULLETIN_ITEMS_PENDING, getBulletinItemsPending);
}

export default bulletinSaga;
