import { IApiRequest, IApiResponse, fetchApi } from '../../core/auth/fetchApiHelper';
import { logger } from '../../core/diagnostics/logger';
import { processConfig } from '../../environment.constants';

import { ICartModel, ICheckOutCartModelResponse, IOrderModel } from './shopping.state.interface';

export interface ICartResponseModel {
  readonly invalidProductIdentifiers: string[];
  readonly updatedCart: ICartModel;
}

export const updateItems = async (cartModel: ICartModel): Promise<IApiResponse<ICartResponseModel>> => {
  try {
    const request: IApiRequest = {
      url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Cart/cart-update-items`,
      method: 'POST',
      contentType: 'application/json',
      requireAccessToken: true,
      body: cartModel
    };

    const response = await fetchApi<ICartResponseModel>(request);
    return response;
  } catch (e) {
    logger.error('error updating cart items', e as Error);
    throw e;
  }
};

export const checkout = async (orderModel: IOrderModel) => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Cart/cart-checkout`,
    method: 'POST',
    contentType: 'application/json',
    requireAccessToken: true,
    body: orderModel
  };

  const response = await fetchApi<ICheckOutCartModelResponse>(request);
  return response.payload;
};

export const getIsCartExpired = async (userId: string) => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Cart/get-is-cart-expired-by-user?userId=${userId}`,
    method: 'GET',
    contentType: 'application/json',
    requireAccessToken: true
  };

  const response = await fetchApi(request);
  return response;
};

export const getCartItems = async (userId: string) => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Cart/get-cart-items?userId=${userId}`,
    method: 'GET',
    contentType: 'application/json',
    requireAccessToken: true
  };

  const response = await fetchApi(request);
  return response;
};
