import { processConfig } from '../../environment.constants';

export const downloadOrderDocument = async (url: string, token: string): Promise<Blob> => {
  const headers = new Headers({
    'Content-Type': 'application/json'
  });

  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Access-Control-Allow-Origin', '*');
  const response = await fetch(
    `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Order/get-order-document?blobUrl=${url}`,
    {
      headers,
      method: 'GET'
    }
  );

  const blob = await response.blob();
  return blob;
};
