import * as bulletinActionInterfaces from './bulletin.actions.interfaces';
import {
  GET_BULLETIN_ITEMS_PENDING,
  GET_BULLETIN_ITEMS_SUCCESS,
  TOGGLE_DISCOUNTED_ITEMS,
  TOGGLE_NEW_PRODUCTS,
  TOGGLE_NOTICES
} from './bulletin.actions.constants';

export const toggleDiscountedItemsAction = (): bulletinActionInterfaces.IToggleDiscountedItems => ({
  type: TOGGLE_DISCOUNTED_ITEMS
});

export const toggleNewProductsAction = (): bulletinActionInterfaces.IToggleNewProducts => ({
  type: TOGGLE_NEW_PRODUCTS
});

export const toggleNoticesAction = (): bulletinActionInterfaces.IToggleNotices => ({
  type: TOGGLE_NOTICES
});

export const getBulletinItemsPendingAction = (): bulletinActionInterfaces.IGetBulletinItemsPending => ({
  type: GET_BULLETIN_ITEMS_PENDING
});

export const getBulletinItemsSuccessAction = (items: any): bulletinActionInterfaces.IGetBulletinItemsSuccess => ({
  type: GET_BULLETIN_ITEMS_SUCCESS,
  payload: { items }
});
