import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { navigateAction } from '../layout.actions';

import paths from './router/paths';

const HeaderHome = () => {
  const headerClasses = useStyles();
  const dispatch = useDispatch();

  const navigate = (url: string) => {
    dispatch(navigateAction(url));
  };

  const handlehomeClick = () => {
    navigate(paths.landing);
  };

  return (
    <Box className={headerClasses.leftContainer}>
      <img src="images/vie/desktop/mx-alt-logo-white-padded-desktop.png" alt="logo" onClick={handlehomeClick} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftContainer: {
      marginLeft: '80px',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 1,
      display: 'flex'
    },
    homeText: {
      color: theme.palette.common.white
    },
    navigation: {
      cursor: 'pointer',
      color: theme.palette.common.white
    },
    plus: {
      color: theme.palette.common.white,
      fontWeight: 'bold'
    }
  })
);

export default HeaderHome;
