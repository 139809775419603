import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { IState } from '../../../state/store.state';
import {
  impersonatePendingAction,
  setCustomerInfoForSuperUserAction,
  toggleImpersonationSelectDialogAction
} from '../customer.actions';
import { impersonatedUserIdSelector, showImpersonationSelectDialogSelector } from '../customer.selectors';

import ImpersonationList from './ImpersonationList';

const ImpersonationSelectDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector((state: IState) => showImpersonationSelectDialogSelector(state));
  const impersonatedUser = useSelector((state: IState) => impersonatedUserIdSelector(state));

  const handleClose = () => {
    dispatch(setCustomerInfoForSuperUserAction(undefined));
    dispatch(toggleImpersonationSelectDialogAction());
  };
  const handleOk = () => {
    dispatch(impersonatePendingAction());
    dispatch(toggleImpersonationSelectDialogAction());
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown={false}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">Shop as</DialogTitle>
      <DialogContent>
        <ImpersonationList />
      </DialogContent>
      <DialogActions>
        <Button disabled={impersonatedUser === undefined} onClick={handleOk} color="primary" variant="text">
          Ok
        </Button>
        <Button onClick={handleClose} color="primary" variant="text">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImpersonationSelectDialog;
