import { createSelector } from 'reselect';

import { IState } from '../../state/store.state';

import { IAuthenticationState } from './authentication.state.interfaces';
import { RegistrationStatus } from './authentication.enumeration';

const auth = (state: IState) => state.authentication;

export const accountSelector = createSelector([auth], (auth: IAuthenticationState) => auth.account);

export const localAccountIdSelector = createSelector(
  [auth],
  (auth: IAuthenticationState): string | undefined => auth.account?.localAccountId
);

export const userNameSelector = createSelector(
  [auth],
  (auth: IAuthenticationState): string | undefined | null => auth.account?.username
);

export const registrationStatusSelector = createSelector(
  [auth],
  (auth: IAuthenticationState): RegistrationStatus => auth.registrationStatus
);

export const isRegistrationSelector = createSelector([auth], (auth): boolean => {
  if (auth && auth.account && auth.account.idTokenClaims) {
    const isRegistration = auth.account?.idTokenClaims?.extension_is_sekota_registration;

    if (isRegistration == null || isRegistration == undefined) {
      return true;
    }

    return auth.account?.idTokenClaims?.extension_is_sekota_registration;
  }
  return false;
});
