export enum OrderDocumentType {
  CustomerPo = 0,
  PackingList = 1,
  ContractualAgreement = 2,
  Certification = 3
}

export enum ISortDirection {
  NotSorted = 0,
  Ascending = 1,
  Decending = 2
}
